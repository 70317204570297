<template>
  <div id="mainPage">
    <div class="topBC">
      <img :src="user.Head ? user.Head:headImgUrl" class="round_icon"
           :style="{border:'solid 2px #FFFFFF'}">
      <span class="userName">{{ user.Name }}</span>
      <em v-if="pageType == 1" class="button" @click="onGoNewPage('testMembershipQuery?pageType=2')">返回</em>
      <em v-if="pageType == 2" class="button" @click="pageType = 1">返回</em>
      <div class="info">
        <ul>
          <li>
            <em>{{ user.Age }}</em>
            <span>年龄</span>
          </li>
          <li>
            <em>{{ user.Height }}</em>
            <span>身高cm</span>
          </li>
          <li>
            <em>{{ user.Weight }}</em>
            <span>体重kg</span>
          </li>
          <li>
            <em>{{ user.Sex == 1 ? '男' : '女' }}</em>
            <span>性别</span>
          </li>
        </ul>
      </div>

    </div>

    <!--实际内容区域-->
    <div class="context" v-if="pageType == 1">
      <!--预约状态-->
      <div class="appointStatus">
        <div class="contentTitle">
          <span>入会测评详情</span>
        </div>
        <div class="contentLine">
          <span>测试时间:</span>
          <em>{{ filterTime(user.RealTestTime) }}</em>
        </div>
        <!--预约商家-->
        <div class="contentLine">
          <span>预约商家:</span>
          <em>{{ user.ShopName }}</em>
        </div>
        <!--商家地址-->
        <div class="contentLine">
          <span>商家地址:</span>
          <em>{{ user.Addr }}</em>
        </div>
      </div>

      <!--动作描述-->
      <div class="actionDesc">
        <div class="contentTitle">
          FMS功能性动作筛查
        </div>
        <ul>
          <li v-for="item  in RsFMS" v-if="item.DispType == 1" @click="pageType = 2">
            <img :src="item.Picture"
                 width="70px" height="100px">
            <div class="actionName">
              {{ (item.Name.length < 6) ? item.Name : item.Name.slice(0, 6) }}
            </div>
            <div class="actionCount">
              <span>完成指标</span><em>{{ item.Score }}</em>
            </div>
            <div class="actionFeed">
              <div class="feedText" v-if="item.Evaluate == 1">差</div>
              <div class="feedText" v-if="item.Evaluate == 2">一般</div>
              <div class="feedText" v-if="item.Evaluate == 3">好</div>
              <div class="feedText" v-if="item.Evaluate == ''">未评</div>

              <img v-if="item.Evaluate == 1" src="../static/images/icon_emoji_bad.png"/>
              <img v-if="item.Evaluate == 2" src="../static/images/icon_emoji_general.png"/>
              <img v-if="item.Evaluate == 3" src="../static/images/icon_emoji_good.png"/>
              <img v-if="item.Evaluate == ''" src="../static/images/icon_emoji_general.png"/>
            </div>
          </li>
          <li class="liSecondLine" v-for="item  in RsFMS" v-if="item.DispType == 3" @click="pageType = 2">
            <img :src="item.Picture" width="100px" height="80px">
            <div class="actionName">
              {{ (item.Name.length < 6) ? item.Name : item.Name.slice(0, 6) }}
            </div>
            <div class="actionCount">
              <span>完成指标</span><em>{{ item.Score }}</em>
            </div>
            <div class="actionFeed">
              <div class="feedText" v-if="item.Evaluate == 1">差</div>
              <div class="feedText" v-if="item.Evaluate == 2">一般</div>
              <div class="feedText" v-if="item.Evaluate == 3">好</div>
              <div class="feedText" v-if="item.Evaluate == ''">未评</div>

              <img class="iconEvaluate" v-if="item.Evaluate == 1" src="../static/images/icon_emoji_bad.png"/>
              <img class="iconEvaluate" v-if="item.Evaluate == 2" src="../static/images/icon_emoji_general.png"/>
              <img class="iconEvaluate" v-if="item.Evaluate == 3" src="../static/images/icon_emoji_good.png"/>
              <img class="iconEvaluate" v-if="item.Evaluate == ''" src="../static/images/icon_emoji_general.png"/>
            </div>
          </li>
        </ul>

      </div>

      <!--运动表现-->
      <div class="actionText">
        <div class="contentTitle">
          身体肌群能力
        </div>
        <ul v-for="item in RsDesc">
          <li @click="pageType = 2">
            <img class="action" :src="item.Picture"/>
            <div class="midContent">
              <span>{{ item.Name }}</span>
              <em>{{ item.Score }}</em>
              <img v-if="item.Evaluate == 1" src="../static/images/icon_smile_bad.png"/>
              <img v-if="item.Evaluate == 2" src="../static/images/icon_smile_general.png"/>
              <img v-if="item.Evaluate == 3" src="../static/images/icon_smile_good.png"/>
              <img v-if="item.Evaluate == 0" src="../static/images/icon_smile_general.png"/>
              <i>{{ item.FUnit }}</i>
            </div>
            <div class="rightText" v-if="item.Evaluate == 1">差</div>
            <div class="rightText" v-if="item.Evaluate == 2">一般</div>
            <div class="rightText" v-if="item.Evaluate == 3">好</div>
            <div class="rightText" v-if="item.Evaluate == 0">一般</div>
          </li>
        </ul>
      </div>

    </div>

    <div class="explain" v-if="pageType == 2">
      <img src="../static/images/pic_actionExplain.png" width="100%" height="630px">
    </div>
    <div style="height: 40px;width: 100%; display: block;  float: left;"/>
    <alert ref="alertPart"></alert>
  </div>
</template>

<script>
import {
  MyOrderFitnessTestDetailList
} from '../api/getApiRes.js'

import bottomTab from '../components/bottomTab'
import alert from '../components/alert'

let qs = require('qs');
import Global from '../Global.js'

export default {
  data() {
    return {
      thisTab: '入会测评预约',
      oftId: '',
      pageType: 1,
      headImgUrl: require('../static/images/mine/header_default.png'),
      form: {
        phone: '',
      },
      user: {
        Name: '',
        Sex: '',
        Age: '',
        Height: '',
        Weight: '',
        Head: ''
      },
      RsFMS: [],
      RsDesc: [],
    }
  },
  mounted() {
    let that = this;
    that.oftId = this.$route.query.OftId;
    that.getTestDetail();
  },
  destroyed() {
  },
  watch: {
    $route(to) {
      if (to.name == 'testMembershipDetail') {
        let that = this;
        that.oftId = this.$route.query.oftId;
        that.getTestDetail();
      }
    },
    'active'() {
      this.getList();
    }
  },
  methods: {
    alertInfo(info) {
      this.$refs.alertPart.openSimpleDialog(info);
    },
    onGoNewPage(path) {
      // if (this.pageType == 1) {
      //   window.history.back();
      // } else {
      //   this.pageType = 1;
      // }
      this.$router.push({path: '/' + path});
    },
    getTestDetail() {
      let that = this;
      let param = {
        oftId: that.oftId
      };
      let postData = qs.stringify(param);
      MyOrderFitnessTestDetailList(postData).then(res => {
        let json = res;
        if (json.Code == 0) {
          that.user = json.UserInfo;
          that.RsFMS = json.Rs1;
          that.RsDesc = json.Rs2;
        } else {
          that.alertInfo(json.Memo);
        }
      })
    },
    filterTime: function (value) {
      if (!value) {
        return 0;
      } else {
        return dateFormatWithoutZone(value, 16);
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      //因为当钩子执行前，组件实例还没被创建
      // vm 就是当前组件的实例相当于上面的 this，所以在 next 方法里你就可以把 vm 当 this 来用了。
      if (to.name == '') {
        vm.getList();
      }
    });
  },
  components: {
    bottomTab, alert
  }
}
</script>

<style scoped>
#mainPage {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  background: url('../static/images/bc_memberShip.png') top center / cover;
  overflow-y: scroll;
}

.topBC {
  text-align: center;
  margin: 10px auto;
  width: 100%;
  left: 42%;
}

.topBC img {
  margin: 10px auto;
}

.round_icon {
  width: 64px;
  height: 64px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 3px;
  border: solid 2px white;
}

.topBC .userName {
  height: 20px;
  line-height: 20px;
  background: rgba(90, 78, 78, 0.68);
  color: white;
  margin: 0 auto;
  padding: 5px 10px;
  border-radius: 15px;
}

.topBC .button {
  width: 50px;
  font-size: 14px;
  height: 25px;
  background: rgba(174, 161, 161, 0.68);
  display: block;
  text-align: center;
  line-height: 25px;
  color: white;
  margin-left: 5%;
  margin-top: -70px;
  border-radius: 3px;
}

.topBC .info {
  height: 77px;
  width: 90%;
  background: rgba(91, 84, 84, 0.68);
  backdrop-filter: blur(10px);
  margin-left: 5%;
  border-radius: 5px;
  margin-top: 60px;
}

.info ul {
  width: 100%;
  overflow: hidden;
  display: block;
  padding-top: 15px;
  margin: 15px auto;
}

.info li {
  width: 25%;
  float: left;
  text-align: center;
}

.info li:nth-child(1) {
  border-right: 1px solid #fff;
}

.info li:nth-child(2) {
  border-right: 1px solid #fff;
}

.info li:nth-child(3) {
  border-right: 1px solid #fff;
}

.info li em {
  width: 100%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  font-weight: 500;
  color: white;
  font-size: 20px;
  text-align: center;
}

.info li span {
  width: 100%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  color: white;
  font-size: 12px;
  text-align: center;
}

.title span {
  display: block;
  font-size: 36px;
  color: white;
  text-align: center;
}

.context .appointStatus {
  width: 90%;
  background: rgba(255, 255, 255, 0.68);
  backdrop-filter: blur(10px);
  margin: 25px auto 0;
  border-radius: 15px;
  padding-bottom: 20px;
  padding-left: 39px;
  padding-right: 39px;
}

.appointStatus .contentTitle {
  height: 55px;
  line-height: 70px;
  font-size: 18px;
  color: #363636;
  margin-bottom: 5px;
  text-align: center;
  width: 100%;
}

.appointStatus .contentLine {
  margin-top: 5px;
}

.appointStatus .contentLine span {
  font-size: 14px;
  font-weight: 500;
}

.appointStatus .contentLine em {
  font-size: 14px;
  margin-left: 12px;
}

.context .actionDesc {
  width: 90%;
  background: #FFFFFF;
  display: block;
  padding-bottom: 20px;
  margin: 20px auto 20px 5%;
  float: left;
  border-radius: 15px;
  text-align: center;
  color: #3B3B3B;
}

.actionDesc .contentTitle {
  font-size: 16px;
  font-weight: 500;
  margin-top: 20px;
}

.actionDesc ul {
  list_style: none;
}

.actionDesc li {
  width: 23%;
  height: 200px;
  border-radius: 5px;
  border: 1px solid #E2E2E2;
  line-height: 28px;
  white-space: nowrap;
  margin: 2% 1% 1%;
  display: block;
  float: left;
}

.actionDesc li .actionName {
  background: #F0F0F0;
  color: #4E4E4E;
  font-size: 12px;
}


.actionDesc li .actionCount {
  color: #4E4E4E;
  text-align: left;
  padding-left: 5%;
  font-size: 10px;
}

.actionDesc li .actionCount span {
  width: 50px;
  display: block;
  float: left;
}

.actionDesc li em {
  color: #FF5800;
  display: block;
  font-size: 18px;
  width: 20px;
}

.actionDesc li .actionFeed {
  border-top: 1px solid #E2E2E2;
}

.actionDesc li .actionFeed .feedText {
  width: 60%;
  height: 32px;
  line-height: 32px;
  border-right: 1px solid #E2E2E2;
}

.actionDesc li .actionFeed .feedText {
  width: 60%;
  height: 32px;
  line-height: 32px;
  border-right: 1px solid #E2E2E2;
}

.actionDesc li .actionFeed img {
  float: right;
  height: 35px;
  width: 25px;
  margin-top: -33px;
}

.actionDesc li .actionFeed .iconEvaluate {
  float: right;
  height: 35px;
  margin-right: 10px;
  width: 25px;
  margin-top: -33px;
}


.actionDesc .liSecondLine {
  width: 31%;
  height: 180px;
  border-radius: 5px;
  border: 1px solid #E2E2E2;
  line-height: 28px;
  white-space: nowrap;
  margin: 2% 1.1% 1%;
  display: block;
  float: left;
}

.context .actionText {
  width: 90%;
  background: #FFFFFF;
  display: block;
  padding-bottom: 20px;
  margin: 20px auto 20px 5%;
  float: left;
  border-radius: 15px;
  text-align: center;
  color: #3B3B3B;
}


.actionText .contentTitle {
  font-size: 16px;
  margin-top: 20px;
}

.actionText ul {
  list_style: none;
}

.actionText li {
  width: 80%;
  margin-top: 10px;
  height: 40px;
  margin-left: 10%;
  line-height: 40px;
  white-space: nowrap;
  display: block;
  float: left;
}

.actionText li .action {
  width: 30px;
  height: 30px;
  margin-top: 5px;
  float: left;
}

.actionText li .midContent {
  height: 34px;
  line-height: 34px;
  margin-left: 35px;
  background: #EFEFEF;
  border-radius: 15px;
  margin-top: 2px;
  width: 80%;

}

.actionText li .midContent span {
  width: 120px;
  font-size: 14px;
  display: block;
  float: left;
  padding-left: 10px;
  text-align: left;
}

.actionText li .midContent em {
  width: 40px;
  float: left;
  display: block;
  font-size: 14px;
  color: #4e4e4e;
}

.actionText li .midContent i {
  font-size: 14px;
  color: #909090;
}

.actionText li img {
  height: 25px;
  width: 25px;
  margin-top: 5px;
  float: right;
  display: block;
}

.actionText li .rightText {
  float: right;
  margin-top: -38px;
  margin-right: -10px;
}

.explain {
  margin: 0 auto;
  width: 90%;
  background: white;
  border-radius: 10px;
  height: 700px;
}


/*响应式调整*/
@media only screen and (max-width: 320px) {
}

@media (min-width: 321px) and (max-width: 344px) {
}

@media (min-width: 345px) and (max-width: 360px) {
}

@media (min-width: 361px) and (max-width: 375px) {
}

@media (min-width: 376px) and (max-width: 396px) {
}

@media (min-width: 397px) and (max-width: 414px) {
}

@media (min-width: 415px) and (max-width: 480px) {
}

@media (min-width: 481px) and (max-width: 640px) {
}
</style>
